<!--
 * @Author: huadan
 * @Date: 2021-04-20 13:22:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-20 17:23:48
 * @Description: 批准审批消息框内容
-->
<template>
  <div>
    <div class="mb-1">{{ name }}</div>
    <div class="mb-1">
      <span>{{ amountText }}: </span>
      <span class="warning">{{ amount }}</span>
    </div>
    <div>
      <span> {{ valueText }}: </span>
      <span class="primary">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuotationApproveMes',
  props: {
    name: {
      type: String,
      default: '',
    },
    amountText: {
      type: String,
      default: '',
    },
    amount: {
      type: String,
      default: '',
    },
    valueText: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
}
</script>
